import { ENV } from './env';

const colorsMap = {
  success: '#22c38e',
  alert: '#eb5757',
  warning: '#f2c94c',
  critical: '#33000f',
};

export const writeConsoleMessage = (
  message: unknown,
  status?: 'alert' | 'warning' | 'success' | 'critical',
) => {
  if (ENV === 'dev') {
    if (status !== 'critical') {
      console.info(
        typeof message === 'object' ? message : `%c${message}`,
        `color: ${colorsMap[status ?? 'success']}`,
      );
    } else {
      console.error(message);
    }
  }
};
